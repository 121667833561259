import { User } from "@firebase/auth"
import { doc, getFirestore, updateDoc, deleteField } from "@firebase/firestore"
import { ProgressDocument } from "../ProgressModel"

export type RemoveModuleAction = {
  action: "removeModule"
  args: RemoveModuleArgs
}

type RemoveModuleArgs = {
  moduleId: string
}

export function removeModule({
  moduleId,
  user,
  setProgress,
}: RemoveModuleArgs & {
  user?: User | null
  setProgress: React.Dispatch<React.SetStateAction<ProgressDocument>>
}): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!user) {
      setProgress((prevProgress: ProgressDocument) => {
        const newProgress: ProgressDocument = {
          ...prevProgress,
          modules: {
            // Remove the module from the progress
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...Object.keys(prevProgress.modules).reduce((acc: { [key: string]: any }, key) => {
              if (key !== moduleId) {
                acc[key] = prevProgress.modules[key]
              }
              return acc
            }, {}),
          },
        }
        localStorage.setItem("progress", JSON.stringify(newProgress))
        return newProgress
      })
      resolve()
      return
    }

    updateDoc(doc(getFirestore(), "en-v6-progress", user.uid), {
      [`modules.${moduleId}`]: deleteField(),
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
