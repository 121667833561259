import { User } from "@firebase/auth"
import { doc, getFirestore, setDoc } from "@firebase/firestore"

import { ChapterProgress, ProgressDocument } from "../ProgressModel"

export type UpdateChapterAction = {
  action: "updateChapter"
  args: UpdateChapterArgs
}

type UpdateChapterArgs = {
  chapterId: string
  chapterProgressData: ChapterProgress
}

export function updateChapter({
  chapterId,
  chapterProgressData,
  user,
  setProgress,
}: UpdateChapterArgs & {
  user?: User | null
  setProgress: React.Dispatch<React.SetStateAction<ProgressDocument>>
}): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!user) {
      setProgress(prevProgress => {
        const newProgress = {
          ...prevProgress,
          chapters: {
            ...prevProgress.chapters,
            [chapterId]: {
              ...(prevProgress.chapters[chapterId] || {}),
              ...chapterProgressData,
            },
          },
        }
        // Save to localStorage
        localStorage.setItem("progress", JSON.stringify(newProgress))
        return newProgress
      })
      resolve()
      return
    }
    setDoc(
      doc(getFirestore(), "en-v6-progress", user.uid),
      {
        chapters: {
          [`${chapterId}`]: chapterProgressData,
        },
      },
      { merge: true },
    )
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
