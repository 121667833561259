/* eslint-disable arrow-body-style */
import { FC } from "react"
import Link from "next/dist/client/link"

import styles from "./UserButton.module.scss"
import ProfileEarthly from "@cs/public/img/_common/profileEarthly.png"
import { Avatar, AvatarImage, AvatarFallback } from "@cs/components/ui/avatar"

export const UserButton: FC<{ src?: string; name?: string }> = ({ src, name }) => {
  return (
    <Link href={"/profile"}>
      <a className={styles.accountLink}>
        <span className={styles.name}>{name?.split(" ")[0] || "Hello!"}</span>
        <span className="srOnly">go to settings</span>
        <Avatar>
          {src ? (
            <AvatarImage src={src} alt="profile pic" />
          ) : (
            <AvatarImage src={ProfileEarthly.src} alt="profile pic" />
          )}
          <AvatarFallback>
            {name
              ?.split(" ")
              .map(n => n[0])
              .join("") || "CS"}
          </AvatarFallback>
        </Avatar>
      </a>
    </Link>
  )
}
