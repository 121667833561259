import { FC } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { Languages } from "lucide-react"

import languageData from "@cs/public/locales/_languages.json"
import styles from "./LanguageDropdown.module.scss"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@cs/components/ui/dropdown-menu"
import { ScrollArea } from "@cs/components/ui/scroll-area"

type LanguagesTypes = {
  [key: string]: {
    name: string
  }
}

export const LanguageDropdown: FC<unknown> = () => {
  const router = useRouter()
  const { t } = useTranslation("landing-page")

  const languages: LanguagesTypes = languageData

  const changeLanguage = (locale: string) => {
    router.push(router.pathname, router.asPath, { locale })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={styles.trigger}>
        <Languages style={{ width: "19px" }} />
        <span>{t("languages")}</span>
      </DropdownMenuTrigger>

      <DropdownMenuContent style={{ width: "250px" }} className={styles.content}>
        <ScrollArea style={{ height: "280px" }}>
          <DropdownMenuLabel>{t("languages")}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {Object.keys(languages).map((locale, index) => {
            const language = languages[locale]
            return (
              <DropdownMenuCheckboxItem
                key={index}
                checked={locale === router.locale}
                onClick={() => changeLanguage(locale)}
              >
                {language.name}
              </DropdownMenuCheckboxItem>
            )
          })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
