import { User } from "@firebase/auth"
import { doc, getFirestore, setDoc } from "@firebase/firestore"

import { ModuleProgress, ProgressDocument } from "../ProgressModel"

export type UpdateModuleAction = {
  action: "updateModule"
  args: UpdateModuleArgs
}

type UpdateModuleArgs = {
  moduleId: string
  moduleProgressData: ModuleProgress
}

export function updateModule({
  moduleId,
  moduleProgressData,
  user,
  setProgress,
}: UpdateModuleArgs & {
  user?: User | null
  setProgress: React.Dispatch<React.SetStateAction<ProgressDocument>>
}): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!user) {
      setProgress(prevProgress => {
        const newProgress = {
          ...prevProgress,
          modules: {
            ...prevProgress.modules,
            [moduleId]: {
              ...(prevProgress.modules[moduleId] || {}),
              ...moduleProgressData,
            },
          },
        }
        // Save to localStorage
        localStorage.setItem("progress", JSON.stringify(newProgress))
        return newProgress
      })
      return
    }
    setDoc(
      doc(getFirestore(), "en-v6-progress", user.uid),
      {
        modules: {
          [`${moduleId}`]: moduleProgressData,
        },
      },
      { merge: true },
    )
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
