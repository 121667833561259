import { Footer } from "screens/_app/footer/Footer"
import { HeaderMainSite } from "@cs/screens/_app/header"
import { GetLayout } from "types/page"

const defaultLayout: GetLayout = page => (
  <>
    <HeaderMainSite />
    {page}
    <Footer />
  </>
)
export default defaultLayout
