/* eslint-disable arrow-body-style */
import { FC } from "react"
import Link from "next/link"
import { Menu } from "lucide-react"

import styles from "./MobileNavigationMenu.module.scss"
import { NavBarProps } from "../nav-bar"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@cs/components/ui/side-sheet"
import { ScrollArea } from "@cs/components/ui/scroll-area"
import { LanguageDropdown } from "../language-dropdown"
import { Logo } from "../logo"

export const MobileNavigationMenu: FC<NavBarProps> = ({ t, navBarItems }) => {
  return (
    <Dialog>
      <DialogTrigger className={styles.trigger}>
        <Menu style={{ width: "30px", height: "auto" }} />
        <span className="srOnly">open menu</span>
      </DialogTrigger>
      <DialogContent
        className={styles.content}
        onCloseAutoFocus={e => e.preventDefault()}
        onOpenAutoFocus={e => e.preventDefault()}
      >
        <DialogHeader className={styles.header}>
          <DialogTitle className={styles.heading}>
            <Logo /> Menu
          </DialogTitle>
        </DialogHeader>
        <ScrollArea>
          <div className={styles.navigationContainer}>
            <nav>
              {navBarItems?.linkItems && (
                <ul className={styles.navigationList}>
                  {navBarItems.linkItems.map((link, index) => (
                    <li key={index}>
                      <Link href={link.href}>
                        <a className={styles.link}>{t(link.name)}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {navBarItems?.dropdownItems &&
                navBarItems.dropdownItems.map((item, index) => (
                  <section key={index}>
                    <h3 className={styles.subHeading}>{t(item.name)}</h3>
                    <ul className={styles.navigationList}>
                      {item.links.map((link, index) => (
                        <Link key={index} href={link.href}>
                          <a className={styles.link}>{t(link.label)}</a>
                        </Link>
                      ))}
                    </ul>
                  </section>
                ))}
            </nav>
          </div>
          <div style={{ padding: "4px" }}>
            <LanguageDropdown />
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
