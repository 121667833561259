import { User } from "@firebase/auth"
import { doc, getFirestore, setDoc } from "@firebase/firestore"

import { CourseProgress, ProgressDocument } from "../ProgressModel"

export type UpdateCourseAction = {
  action: "updateCourse"
  args: UpdateCourseArgs
}

type UpdateCourseArgs = {
  courseId: string
  courseProgressData: CourseProgress
}

export function updateCourse({
  courseId,
  courseProgressData,
  user,
  setProgress,
}: UpdateCourseArgs & {
  user?: User | null
  setProgress: React.Dispatch<React.SetStateAction<ProgressDocument>>
}): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!user) {
      setProgress(prevProgress => {
        const newProgress = {
          ...prevProgress,
          courses: {
            ...prevProgress.courses,
            [courseId]: {
              ...(prevProgress.courses[courseId] || {}),
              ...courseProgressData,
            },
          },
        }
        // Save to localStorage
        localStorage.setItem("progress", JSON.stringify(newProgress))
        return newProgress
      })
      resolve()
      return
    }
    setDoc(
      doc(getFirestore(), "en-v6-progress", user.uid),
      {
        courses: {
          [`${courseId}`]: courseProgressData,
        },
      },
      { merge: true },
    )
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
