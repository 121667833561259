import { FC } from "react"
import Link from "next/link"
import Image from "next/image"

import styles from "./Logo.module.scss"
import CSLogoSmall from "@cs/public/img/icons/planet_1.png"

export const Logo: FC = () => (
  <Link href="/">
    <a className={styles.anchor}>
      <span className="srOnly">back to home</span>
      <span className={styles.smallLogo}>
        <Image src={CSLogoSmall.src} width={50} height={50} objectFit="cover" alt="Earthly" />
      </span>
      <span className={styles.bigLogo}>
        <Image src="/img/cs-logo-2021-light.png" width={210} height={56} alt="ClimateScience" />
      </span>
    </a>
  </Link>
)
