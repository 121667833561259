import { FC, useEffect } from "react"
import Head from "next/head"
import { appWithTranslation } from "next-i18next"
import DefaultSeo from "@cs/screens/_app/seo/default-seo"
import nextI18nextConfig from "../next-i18next.config.js"
import { MyAppProps } from "types/page"
import defaultLayout from "layouts/Default.layout"
import { AppProps } from "next/dist/next-server/lib/router/router"
import "../styles/globals.scss"
import "@climate-science/component-library/dist/index.css"
import { useRouter } from "next/router"
import Logger from "js-logger"
import { isRTL } from "@cs/lib/getDirFromLocale/index"
import i18n from "../next-i18next.config"
import toDashCase from "@cs/lib/toDashCase"
import { ProgressProvider } from "@cs/lib/progress-v6/ProgressProvider"
import { CookieConsentBanner } from "@cs/components/consent-banner"
import { Hotjar } from "@cs/components/hotjar"
import { useReferralCookies } from "@cs/lib/referrals"

function MyApp({ Component, pageProps }: MyAppProps) {
  const { setEventId, setReferralPartner, setUserReferral } = useReferralCookies()
  const getLayout = Component.getLayout || defaultLayout
  const router = useRouter()
  const dir = isRTL(router.locale) ? "rtl" : "ltr"

  useEffect(() => {
    document.documentElement.dir = dir
  }, [dir])

  useEffect(() => {
    const loadTracking = async () => {
      const { MixpanelTracking } = await import("@cs/services/mixpanel/client-side")
      const { initGA } = await import("@cs/lib/analytics")
      MixpanelTracking.getInstance().pageViewed()
      initGA()
    }
    const loadLogs = async () => {
      const { logPageView } = await import("@cs/lib/analytics")
      logPageView()
    }

    loadTracking()

    const urlParams = new URLSearchParams(window.location.search)
    const partner = urlParams.get("partner")?.split("?")[0] ?? undefined
    const userReferral = urlParams.get("csoref")?.split("?")[0] ?? undefined
    const privateEventId = urlParams.get("event")?.split("?")[0] ?? undefined

    if (partner) {
      setReferralPartner(partner)
    }
    if (userReferral) {
      setUserReferral(userReferral)
    }
    if (privateEventId) {
      setEventId(privateEventId)
    }

    Logger.log("referral partner set to", partner)
    Logger.log("user referral set to", userReferral)

    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", loadLogs)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", loadLogs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <link
          rel="canonical"
          href={`https://${process.env.NEXT_PUBLIC_DOMAIN}${
            router.locale != "en" ? "/" + router.locale : ""
          }${router.pathname}`}
        />

        {/* links to different langs */}
        {i18n.i18n.locales.map((locale, key) => {
          const lang = locale == "en" ? "" : "/" + locale

          return (
            <link
              key={key}
              rel="alternate"
              hrefLang={toDashCase(locale)}
              href={`https://${process.env.NEXT_PUBLIC_DOMAIN}${lang}${router.pathname}`}
            />
          )
        })}
      </Head>
      <DefaultSeo />
      <ProgressProvider>{getLayout(<Component {...pageProps} />)}</ProgressProvider>
      <CookieConsentBanner />
      <Hotjar />
    </>
  )
}

export default appWithTranslation(MyApp as FC<AppProps>, nextI18nextConfig)
