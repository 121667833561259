import React from "react"

import { hasCookieConsent } from "util/hasCookieConsent"
import useHotjar from "react-use-hotjar"

const key = Number(process.env.NEXT_PUBLIC_HOTJAR_KEY || "")

export const Hotjar: React.FC = () => {
  const { initHotjar } = useHotjar()
  const hasConsent = typeof window === "undefined" || hasCookieConsent()
  React.useEffect(() => {
    if (typeof window === "undefined" || !hasConsent) return
    initHotjar(key, 6)
  }, [hasConsent, initHotjar])

  return null
}
