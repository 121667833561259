import { User } from "@firebase/auth"
import { doc, getFirestore, setDoc } from "@firebase/firestore"

import { ProgressDocument, SectionProgress } from "../ProgressModel"

export type UpdateSectionAction = {
  action: "updateSection"
  args: UpdateSectionArgs
}

type UpdateSectionArgs = {
  sectionId: string
  sectionProgressData: SectionProgress
}

export function updateSection({
  sectionId,
  sectionProgressData,
  user,
  setProgress,
}: UpdateSectionArgs & {
  user?: User | null
  setProgress: React.Dispatch<React.SetStateAction<ProgressDocument>>
}): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!user) {
      setProgress(prevProgress => {
        const newProgress = {
          ...prevProgress,
          sections: {
            ...prevProgress.sections,
            [sectionId]: {
              ...(prevProgress.sections[sectionId] || {}),
              ...sectionProgressData,
            },
          },
        }
        // Save to localStorage
        localStorage.setItem("progress", JSON.stringify(newProgress))
        return newProgress
      })
      resolve()
      return
    }
    setDoc(
      doc(getFirestore(), "en-v6-progress", user.uid),
      {
        sections: {
          [`${sectionId}`]: sectionProgressData,
        },
      },
      { merge: true },
    )
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
