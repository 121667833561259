/* eslint-disable arrow-body-style */
import * as React from "react"
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu"
import { FaChevronDown } from "react-icons/fa"
import styles from "./NavigationMenu.module.scss"

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root className={` ${className} ${styles.root} `} ref={ref} {...props}>
    {children}
    <NavigationMenuViewport />
  </NavigationMenuPrimitive.Root>
))
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.List className={` ${className} ${styles.list} `} ref={ref} {...props}>
    {children}
    <NavigationMenuIndicator />
  </NavigationMenuPrimitive.List>
))
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Item className={` ${className} ${styles.item} `} ref={ref} {...props}>
    {children}
  </NavigationMenuPrimitive.Item>
))
NavigationMenuItem.displayName = NavigationMenuPrimitive.Item.displayName

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    className={` ${className} ${styles.trigger} `}
    ref={ref}
    {...props}
  >
    {children} <FaChevronDown className={styles.chevron} aria-hidden={true} />
  </NavigationMenuPrimitive.Trigger>
))
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    className={` ${className} ${styles.content} `}
    ref={ref}
    {...props}
  >
    <ul className={styles.contentList}>{children}</ul>
  </NavigationMenuPrimitive.Content>
))
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Link
    className={` ${className} ${styles.menuLink} `}
    ref={ref}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Link>
))
NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    className={` ${className} ${styles.indicator} `}
    ref={ref}
    {...props}
  >
    <div className={styles.indicatorArrow} />
  </NavigationMenuPrimitive.Indicator>
))
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName

interface ListItemProps {
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactElement<any>
  description?: string
}

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, children, description, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuPrimitive.Link asChild>
          {children &&
            React.cloneElement(
              children,
              {
                className: `${className} ${styles.listItemLink} `,
                ...props,
                ref: ref,
              },
              <>
                <div className={styles.listItemTitle}>{children.props.children}</div>
                {description && <div className={styles.listItemDescription}>{description}</div>}
              </>,
            )}
        </NavigationMenuPrimitive.Link>
      </li>
    )
  },
)
ListItem.displayName = "ListItem"

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <div className={styles.viewportPosition}>
    <NavigationMenuPrimitive.Viewport
      className={` ${className} ${styles.viewport} `}
      ref={ref}
      {...props}
    />
  </div>
))
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName

export {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  ListItem,
}
