import React from "react"

import { ProgressDocument } from "./ProgressModel"
import {
  UpdateChapterAction,
  UpdateModuleAction,
  UpdateCourseAction,
  UpdateSectionAction,
  RemoveModuleAction,
} from "./actions"

type ProgressActions =
  | UpdateModuleAction
  | UpdateChapterAction
  | UpdateCourseAction
  | UpdateSectionAction
  | RemoveModuleAction

export type UpdateProgressType = (arg: ProgressActions) => Promise<void>

interface ProgressContextData {
  progress: ProgressDocument
  updateProgress: UpdateProgressType
  isLoading: boolean
}

export const ProgressContext = React.createContext<ProgressContextData>({
  progress: { courses: {}, chapters: {}, sections: {}, modules: {} },
  updateProgress: () => Promise.resolve(),
  isLoading: true,
})

export const useProgress = () => React.useContext(ProgressContext)
