import { FC } from "react"
import { useTranslation } from "next-i18next"

import navBarItems from "./nav-items-cso22.json"
import { Header } from "./Header"

export const HeaderCSO22: FC = () => {
  const { t } = useTranslation("olympiad")

  return <Header t={t} navBarItems={navBarItems} />
}
