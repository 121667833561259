import { useCookies } from "react-cookie"

const X_CSO22EVENT_ID = "x-cso22-event-id"
const X_RARTNER_REFERRER = "x-referral-partner"
const X_USER_REFERRAL = "x-user-referral"

const FOURTEEN_DAYS = 3600 * 1000 * 24 * 14

interface UseReferralCookies {
  setEventId: (eventId?: string) => void
  getEventId: () => string | undefined
  setReferralPartner: (referralPartner?: string) => void
  getReferralPartner: () => string | undefined
  setUserReferral: (referral?: string) => void
  getUserReferral: () => string | undefined
}

const useReferralCookies = (): UseReferralCookies => {
  const [cookies, setCookie] = useCookies([X_CSO22EVENT_ID, X_RARTNER_REFERRER, X_USER_REFERRAL])

  function setEventId(eventId?: string) {
    if (!eventId) return

    const xReferrer = getEventId()
    if (xReferrer) return

    const expiresIn = FOURTEEN_DAYS
    const date = new Date()
    date.setTime(date.getTime() + expiresIn)

    setCookie(X_CSO22EVENT_ID, eventId, {
      path: "/",
      expires: date,
      sameSite: "none",
      secure: true,
    })
  }

  function getEventId(): string | undefined {
    return cookies[X_CSO22EVENT_ID]
  }

  function setReferralPartner(referralPartner?: string) {
    if (!referralPartner) return

    const xReferrer = getReferralPartner()
    if (xReferrer) return

    const expiresIn = FOURTEEN_DAYS
    const date = new Date()
    date.setTime(date.getTime() + expiresIn)

    setCookie(X_RARTNER_REFERRER, referralPartner, {
      path: "/",
      expires: date,
      sameSite: "none",
      secure: true,
    })
  }

  function getReferralPartner(): string | undefined {
    return cookies[X_RARTNER_REFERRER]
  }

  function setUserReferral(referral?: string) {
    if (!referral) return

    setCookie(X_USER_REFERRAL, referral, {
      path: "/",
      sameSite: "none",
      secure: true,
    })
  }

  function getUserReferral(): string | undefined {
    return cookies[X_USER_REFERRAL]
  }

  return {
    setEventId,
    getEventId,
    setReferralPartner,
    getReferralPartner,
    setUserReferral,
    getUserReferral,
  }
}

export { useReferralCookies }
