import { DefaultSeo, DefaultSeoProps } from "next-seo";
import { useTranslation } from "next-i18next";

import config from "@cs/config/seo/default-seo-config";

const CsDefaultSeo = () => {
  const { t } = useTranslation("seo");

  const seo: DefaultSeoProps = {
    ...config,
    title: t(config.title),
    description: t(config.description),
    openGraph: {
      ...config.openGraph,
      title: t(config.openGraph.title),
    },
  };

  return <DefaultSeo {...seo} />;
};

export default CsDefaultSeo;
