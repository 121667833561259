import { CLIMATE_SCIENCE_WEBSITE, TWITTER_HANDLE } from "./social-media-config";

const TITLE_STRING_ID = "climate-science"
const IMAGE_ALT = "ClimateScience Logo"
const DESCRIPTION = 
'Take action on system climate solutions. Science-backed, easy-to-understand climate education for everyone.'

const sizes = [16, 32, 72, 96, 120, 128, 144, 154, 167, 180, 192, 384, 512]
const images: { url: string; height: number; width: number; alt: string }[] = []

sizes.forEach(size => {
  images.push({
    url: `climate-science/icons/climate-science-icon-${size}x${size}.png`,
    height: size,
    width: size,
    alt: IMAGE_ALT,
  })
})

const DefaultSeoConfig = {
  title: TITLE_STRING_ID,
  description: DESCRIPTION,
  titleTemplate: "%s | ClimateScience",
  openGraph: {
    title: TITLE_STRING_ID,
    description: DESCRIPTION,
    type: "website",
    url: CLIMATE_SCIENCE_WEBSITE,
    images: images,
  },
  twitter: {
    handle: TWITTER_HANDLE,
    site: TWITTER_HANDLE,
    cardType: "summary_large_image",
  },
}

export default DefaultSeoConfig
