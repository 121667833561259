/* eslint-disable arrow-body-style */
import { FC } from "react"
import Link from "next/link"
import { TFunction } from "next-i18next"

import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  ListItem,
} from "@cs/components/ui/navigation-menu"

interface DropdownItemProps {
  name: string
  links: {
    label: string
    description: string
    href: string
  }[]
}

interface LinkItemProps {
  name: string
  href: string
}

interface NavBarItemsProps {
  dropdownItems?: DropdownItemProps[]
  linkItems?: LinkItemProps[]
}

export interface NavBarProps {
  t: TFunction
  navBarItems?: NavBarItemsProps
}

export const NavBar: FC<NavBarProps> = ({ t, navBarItems }) => {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        {navBarItems?.linkItems &&
          navBarItems.linkItems.map((link, index) => (
            <NavigationMenuItem key={index}>
              <Link href={link.href} passHref>
                <NavigationMenuLink asChild>
                  <a>{t(link.name)}</a>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ))}
        {navBarItems?.dropdownItems &&
          navBarItems.dropdownItems.map((item, index) => (
            <NavigationMenuItem key={index}>
              <NavigationMenuTrigger>{t(item.name)}</NavigationMenuTrigger>
              <NavigationMenuContent>
                {item.links.map((link, index) => (
                  <Link key={index} href={link.href} passHref>
                    <ListItem description={t(link.description)}>
                      <a>{t(link.label)}</a>
                    </ListItem>
                  </Link>
                ))}
              </NavigationMenuContent>
            </NavigationMenuItem>
          ))}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
