export const RTL_LOCALES: string[] = [
  "ar",
  "he",
  "fa"
]

export const isRTL = (locale:string | undefined): boolean => {
  if (!locale) return false
  return RTL_LOCALES.includes(locale)
}
