export default function toDashCase (string:string):string {
  const reMayus = new RegExp(/[A-Z]/)
  const isSimple = string.match(reMayus) || true

  if (isSimple == true ) {
    return string
  }

  if (isSimple.index) {
    const array = string.split(string[isSimple.index])
    const secondString = '-' + string[isSimple.index] + string[isSimple.index+1] 
    array[1] = secondString.toString().toLowerCase()

    return array.toString().replace(',', '')
  }

  return ''
}