import { FC } from "react"
import { FaAngleRight } from "react-icons/fa"
import { useTranslation } from "next-i18next"

import styles from "./SignInUpButtons.module.scss"
import { Button } from "@cs/components/ui/button"
import { useAccountRedirect } from "@cs/components/hooks/useAccountRedirect"

export const SignInUpButtons: FC<unknown> = () => {
  const { redirectToSignIn, redirectToSignup } = useAccountRedirect()
  const { t } = useTranslation("nav")

  return (
    <div className={styles.container}>
      <Button underline className={styles.signIn} onClick={redirectToSignIn} data-cy="sign-in">
        {t("sign-in")}
      </Button>
      <Button className={styles.signUp} onClick={redirectToSignup} data-cy="sign-up">
        {t("sign-up")} <FaAngleRight />
      </Button>
    </div>
  )
}
