import { i18n } from "next-i18next"

// When the i18n store are empty, this populates them
// locale = from router.locale
// ns : string or string[] of the name spaces to be populated
// fallback lang, if not provided, fallbackLng = "en"
export const populateStore = async (
  locale:string, 
  ns:string,
  fallbackLng?:string
) => {
  //imports the resources directly from the json
  const coursesFB = await import(`../public/locales/${fallbackLng || 'en'}/${ns}.json`)
  const courses = await import(`../public/locales/${locale}/${ns}.json`)
  // add the resources to the unpopulated store
  i18n?.addResources(fallbackLng as string, ns, coursesFB)
  i18n?.addResources(locale as string, ns, courses)
}