import React, { FC, useEffect, useState } from "react"
import { Trans } from "next-i18next"
import { useRouter } from "next/dist/client/router"
import { browserSessionPersistence, setPersistence, signInWithCustomToken } from "firebase/auth"
import { X } from "lucide-react"
import { Balancer } from "react-wrap-balancer"

import styles from "./Header.module.scss"
import { Logo } from "./logo"
import { UserButton } from "./user-button"
import { SignInUpButtons } from "./signinup-buttons"
import { LanguageDropdown } from "./language-dropdown"
import { NavBar, NavBarProps } from "./nav-bar"
import { MobileNavigationMenu } from "./mobile-navigation-menu"
import { populateStore } from "util/populatei18nStore"
import Logger from "js-logger"
import { auth } from "@cs/lib/firebase/firebaseConfig"
import { UnderlineLink } from "@climate-science/component-library"

interface HeaderProps extends NavBarProps {
  showBanner?: boolean
}

export const Header: FC<HeaderProps> = ({ t, navBarItems, showBanner = false }) => {
  const router = useRouter()

  const [user, setUser] = useState(auth.currentUser)
  const [isLoading, setIsLoading] = useState(true)
  const [closeBanner, setCloseBanner] = useState(true)
  const [count, setCount] = useState(0)
  const CLOSE_TIMES = 3
  const VERSION = "1.1"

  useEffect(() => {
    const identify = async (id: string) => {
      const { MixpanelService } = await import("@cs/services/mixpanel")
      MixpanelService.identifyUser(id)
    }

    const fn = async () => {
      await populateStore(router.locale as string, "nav", "en")
    }
    fn()

    const checkCurrentUser = async () => {
      setIsLoading(true)
      try {
        const currentUser = auth.currentUser
        if (currentUser) {
          setUser(currentUser)
        } else {
          await setPersistence(auth, browserSessionPersistence)
          const csrfToken = await fetch("/api/generateCsrfToken").then(response => response.text())
          const response = await fetch(
            `https://account.${process.env.NEXT_PUBLIC_DOMAIN}/api/sessionStatus`,
            {
              headers: { "X-CSRF-Token": csrfToken },
              credentials: "include",
            },
          )
          if (response.status === 200) {
            const { token } = await response.json()
            const userCredential = await signInWithCustomToken(auth, token)
            Logger.info("signed in with token")
            const u = userCredential.user
            identify(u.uid)
            setUser(u)
          } else {
            throw new Error("Cannot silently log in")
          }
        }
      } catch (error) {
        Logger.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    checkCurrentUser()

    const isSessionClosed = window.sessionStorage.getItem(`closeBanner-${VERSION}`)
    const isLocalClosed = window.localStorage.getItem(`closeBanner-${VERSION}`)
    if (isLocalClosed || isSessionClosed) setCloseBanner(true)

    const storedCount = window.localStorage.getItem(`counter-${VERSION}`)
    setCount(storedCount ? parseInt(storedCount, 10) : 0)
  }, [router.locale])

  const closeAnnouncement = () => {
    setCloseBanner(true)
    if (count >= CLOSE_TIMES) {
      window.localStorage.setItem(`closeBanner-${VERSION}`, "true")
    } else {
      window.localStorage.setItem(`counter-${VERSION}`, (count + 1).toString())
      window.sessionStorage.setItem(`closeBanner-${VERSION}`, "true")
    }
  }

  const Banner = () => (
    <div className={styles.banner}>
      <p className={styles.bannerText}>
        <Balancer>
          <Trans
            t={t}
            i18nKey={"banner-title"}
            components={[
              <UnderlineLink
                className={styles.bannerLink}
                target="_blank"
                href="https://calendly.com/kanika-cs"
                key="0"
              />,
              <a className={styles.bannerLink} href={"/olympiad"} key="1" />,
            ]}
          />
        </Balancer>
      </p>
      <button
        style={{ display: "inline-flex" }}
        aria-label="close announcement"
        onClick={closeAnnouncement}
      >
        <X />
      </button>
    </div>
  )

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Logo />
          <div className={styles.navBarContainer}>
            <NavBar t={t} navBarItems={navBarItems} />
            <LanguageDropdown />
          </div>
          <div className={styles.headerRightContainer}>
            {!isLoading &&
              (user ? (
                <UserButton
                  src={user?.photoURL || undefined}
                  name={user?.displayName || undefined}
                />
              ) : (
                <SignInUpButtons />
              ))}
            <span className={styles.mobileNavigation}>
              <MobileNavigationMenu t={t} navBarItems={navBarItems} />
            </span>
          </div>
        </div>
      </header>
      {showBanner && !closeBanner && <Banner />}
    </>
  )
}
