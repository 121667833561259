import React from "react"
import { Slot } from "@radix-ui/react-slot"
import styles from "./Button.module.scss"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  light?: boolean
  underline?: boolean
  block?: boolean
  asChild?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ light, underline, block, asChild, disabled, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={`
                ${className}
            ${styles.button}
${disabled && styles.disabled}
            ${light && styles.light}
            ${underline && styles.underline}
            ${block && styles.block}
            `}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = "Button"
