import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { FC } from "react";
import styles from "./Footer.module.scss";
import footerDocument from "./footer.json";

export const Footer: FC = () => {
  const { t } = useTranslation("footer")

  return (
    <footer>
      <section className={styles.innerFooter}>
        {footerDocument.map((section, index) => (
          <div key={index} className={styles.section}>
            <span>{t(section.name)}</span>
            <ul
              className={`${styles.subsections} ${
                section.unibody && styles.unibody
              }`}
            >
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  {link.link !== null ? (
                    <Link href={link.link}>
                      <a>{t(link.name)}</a>
                    </Link>
                  ) : (
                    <>{t(link.name)}</>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
      <hr className={styles.footerLine} />
      <section className={styles.copyright}>
        <p>
          {t("copyright")} © {new Date().getFullYear()} Climate Science
          Ltd.&nbsp;
          {t("all-rights-reserved")}
        </p>
      </section>
    </footer>
  )
}
