import { initializeApp, getApp, getApps } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import {
  browserLocalPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth"

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
}

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()

// let AppCheckInstance = null

// if (typeof window !== "undefined" && !AppCheckInstance) {
//   import("firebase/app-check").then(firebaseAppCheck => {
//     const { initializeAppCheck, ReCaptchaV3Provider } = firebaseAppCheck
//     const captcha = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string
//     AppCheckInstance = initializeAppCheck(app, {
//       provider: new ReCaptchaV3Provider(captcha),
//       isTokenAutoRefreshEnabled: true,
//     })
//   })
// }

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean
  }
}

if (typeof window !== "undefined" && window.location.hostname === "localhost") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

const db = getFirestore(app)
const storage = getStorage(app)
const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
})

export { app, auth, db, storage }
