/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React from "react"
import Link from "next/link"
import Image from "next/image"
import { useCookies } from "react-cookie"

import styles from "./CookieConsentBanner.module.scss"
import { Button } from "../ui/button"
import { ScrollArea } from "../ui/scroll-area"
import Earthly from "@cs/public/img/earthlies/peek_earthly.png"

const analyticsCookies = [
  {
    name: "Google Analytics Cookie",
    description: "Identifies users anonymously.",
  },
  {
    name: "Google Analytics Throttling Cookie",
    description: "Prevents data overload on busy sites by reducing data collection.",
  },
  {
    name: "Google Analytics User ID Cookie",
    description:
      "Uses an anonymous ID to collect user interaction data without personal identifiers.",
  },
  {
    name: "Hotjar Cookie",
    description:
      "Gathers anonymous, aggregated insights into user interactions like clicks and scrolls.",
  },
  {
    name: "Mixpanel Cookie",
    description: "Anonymously tracks user actions to improve site usability.",
  },
]

const essentialCookies = [
  {
    name: "Session Cookie",
    description:
      "Critical for website operation; maintains functionality across sessions. Cannot be disabled without impacting site performance.",
  },
]

type Consents = {
  analytics: boolean | null
}

export const CookieConsentBanner: React.FC = () => {
  const [cookies, setCookie] = useCookies(["CookieConsent"])
  const [showSettings, setShowSettings] = React.useState(false)
  const [consents, setConsents] = React.useState<Consents>({
    analytics: null,
  })

  const hasConsentCookie = cookies.CookieConsent !== undefined

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cookieOptions: any = {
    path: "/",
    maxAge: 365 * 24 * 60 * 60,
    sameSite: "lax",
  }

  if (process.env.NODE_ENV === "production") {
    cookieOptions.domain = `.${process.env.NEXT_PUBLIC_DOMAIN}`
  }

  const handleOptIn = () => {
    setCookie("CookieConsent", "true", cookieOptions)
    window.location.reload()
  }

  const handleOptOut = () => {
    setCookie("CookieConsent", "false", cookieOptions)
  }

  const handleShowSettings = () => {
    setShowSettings(true)
  }

  const handleSettingsSave = () => {
    setShowSettings(false)
    if (consents.analytics === null) return
    setCookie("CookieConsent", consents.analytics, cookieOptions)
    window.location.reload()
  }

  if (hasConsentCookie) {
    return null
  }

  return (
    <div className={styles.banner}>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <Image src={Earthly} alt="Earthly" />
        </div>
        {!showSettings && (
          <>
            <p className={styles.description} style={{ marginTop: 0 }}>
              We collect cookies only to improve our site,{" "}
              <strong>we don&apos;t sell or share data</strong>. Still, you can{" "}
              <button className={styles.link} onClick={handleShowSettings}>
                manage your preferences
              </button>{" "}
              here. To learn more, please go through our{" "}
              <Link href="/privacy">
                <a className={styles.link}>Privacy & Cookie Policy</a>
              </Link>
              .
            </p>
            <Button
              underline
              onClick={handleOptIn}
              style={{ marginRight: "16px" }}
              className={styles.greenBtn}
            >
              Accept
            </Button>
            <Button underline onClick={handleOptOut} className={styles.greenBtn}>
              Decline
            </Button>
          </>
        )}
        {showSettings && (
          <div>
            <ScrollArea className={styles.settings}>
              <div>
                <button
                  onClick={() => setConsents({ ...consents, analytics: !consents.analytics })}
                  className={`${styles.btn} ${
                    consents.analytics === true ? styles.active : styles.inactive
                  }`}
                  style={{ marginTop: 0 }}
                >
                  Analytics
                </button>
                <span>
                  {consents.analytics === true ? "Thank you! :)" : "Click to accept cookies"}
                </span>
                <table className={styles.cookieTable}>
                  <tbody>
                    {analyticsCookies.map((cookie: any) => (
                      <tr key={cookie.name}>
                        <td>
                          <strong>{cookie.name}</strong>
                        </td>
                        <td>{cookie.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <button disabled className={`${styles.btn} ${styles.active} ${styles.disabled}`}>
                  Essentials
                </button>
                <table className={styles.cookieTable}>
                  <tbody>
                    {essentialCookies.map((cookie: any) => (
                      <tr key={cookie.name}>
                        <td>
                          <strong>{cookie.name}</strong>
                        </td>
                        <td>{cookie.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollArea>
            <Button
              underline
              onClick={() => {
                handleSettingsSave()
              }}
              className={styles.greenBtn}
              style={{ marginTop: "20px" }}
            >
              Save Preferences
            </Button>
            <Button underline onClick={() => setShowSettings(false)} style={{ marginLeft: "20px" }}>
              Close
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
