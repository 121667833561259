import { useRouter } from "next/router"

export function useAccountRedirect() {
  const router = useRouter()

  const accountRedirect = (action: string) => {
    const locale: string = router.locale !== "en" ? (router.locale as string) : ""
    return `https://account.${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${action}?redirect=${window.location.href}`
  }

  const redirectToSettings = () => {
    window.location.href = accountRedirect("settings/account")
  }

  const redirectToSignup = () => {
    window.location.href = accountRedirect("signup")
  }

  const redirectToSignIn = () => {
    window.location.href = accountRedirect("login")
  }

  return {
    accountRedirect,
    redirectToSettings,
    redirectToSignup,
    redirectToSignIn,
  }
}
