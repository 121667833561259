/* eslint-disable indent */
import React from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { doc, getFirestore, onSnapshot, setDoc } from "@firebase/firestore"

import { ProgressDocument } from "./ProgressModel"
import { updateChapter, updateModule, updateCourse, updateSection, removeModule } from "./actions"
import { ProgressContext, UpdateProgressType } from "./ProgressContext"
import { auth } from "@cs/lib/firebase/firebaseConfig"

export const ProgressProvider: React.FC = ({ children }) => {
  const initDoc: ProgressDocument = {
    courses: {},
    chapters: {},
    sections: {},
    modules: {},
  }
  const [user, loading] = useAuthState(auth)
  const [progress, setProgress] = React.useState<ProgressDocument>(initDoc)
  const [isLoading, setLoading] = React.useState(true)
  const localDataLoaded = React.useRef(false)

  React.useEffect(() => {
    if (!localDataLoaded.current) {
      const localProgress = localStorage.getItem("progress")
      if (localProgress) {
        setProgress(JSON.parse(localProgress) as ProgressDocument)
      }
      localDataLoaded.current = true
    }
  }, [])

  React.useEffect(() => {
    if (loading) {
      setLoading(true)
      return
    }
    if (!user) {
      setLoading(false)
      return
    }
    const ref = doc(getFirestore(), "en-v6-progress", user.uid)
    const unsub = onSnapshot(
      ref,
      async progressDoc => {
        if (progressDoc.exists()) {
          setProgress(progressDoc.data() as ProgressDocument)
        } else {
          await setDoc(ref, progress)
          localStorage.removeItem("progress")
        }
        setLoading(false)
      },
      () => {
        setLoading(false)
      },
    )
    return () => unsub()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading])

  const updateProgress: UpdateProgressType = action => {
    if (loading) {
      return Promise.resolve()
    }

    switch (action.action) {
      case "updateModule": {
        return updateModule({
          ...action.args,
          user: user,
          setProgress,
        })
      }
      case "updateChapter": {
        return updateChapter({
          ...action.args,
          user: user,
          setProgress,
        })
      }
      case "updateCourse": {
        return updateCourse({
          ...action.args,
          user: user,
          setProgress,
        })
      }
      case "updateSection": {
        return updateSection({
          ...action.args,
          user: user,
          setProgress,
        })
      }
      case "removeModule": {
        return removeModule({
          ...action.args,
          user: user,
          setProgress,
        })
      }
    }
  }

  return (
    <ProgressContext.Provider value={{ progress, updateProgress, isLoading }}>
      {children}
    </ProgressContext.Provider>
  )
}
