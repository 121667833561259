export function hasCookieConsent(): boolean {
  const consentCookie = document.cookie.split("; ").find(row => row.startsWith("CookieConsent="))

  if (!consentCookie) {
    return false
  }
  return consentCookie.split("=")[1] === "true"
}

export function removeCookies(): void {
  document.cookie.split(";").forEach(c => {
    document.cookie = c.trim().split("=")[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC"
  })
}
